import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../../apiInterceptor";
import { API_URL } from "../../client";

export const getInstagramCourses = createAsyncThunk("getInstagramCourses", async (type) => {
  try {
    const res = await api.get(`${API_URL}/course/type?courseType=${type}`);
    console.log("mujeeb111",res)
    return {
      status: res?.status,
      data: res?.data,
    };
  } catch (error) {
    return {
      message: error?.response?.data?.error,
      status: error?.response?.status,
    };
  }
});

