import { configureStore } from "@reduxjs/toolkit";
import profile from "./reducer/profile";
import getUserDetail from "./reducer/getUserDetail";
import getUserProfile from "./reducer/getUserProfile";
import getInstagramCourses from "./reducer/getInstagramCourses";
import getTwitterCourses from "./reducer/getTwitterCourses";
import getFolder from "./reducer/getFolder"
import getCourse from "./reducer/getCourse"
import getcontent from "./reducer/getcontent"
import getSpecificContent from "./reducer/getspecificcontent";
// import getCourseWithType from "./reducer/getCourseWithType";
export const store = configureStore({
  reducer: {
    // profile: profile,
    // user: getUserDetail,
    // getProfile:getUserProfile
    // getCousesWithType: getCourseWithType,
    getInstagramCourses: getInstagramCourses,
    getTwitterCourses: getTwitterCourses,
    getFolder: getFolder,
    getCourse: getCourse,
    getcontent: getcontent,
    getSpecificContent: getSpecificContent
  },
});









