import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getcontent,getSpecificContent } from "../middleware/addContent"
const initialState = {
  loading: false,
  error: "",
  getspecificcontent: {},
};
const getspecificcontentSlice = createSlice({
  name: "getspecificcontent",
  initialState,
  reducers: [],
  extraReducers: (builder) => {
    builder.addCase(getSpecificContent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSpecificContent.fulfilled, (state, action) => {
      state.loading = false;
      state.profile = action.payload;
    });
    builder.addCase(getSpecificContent.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error || "something wrong";
    });
  },
});
export default getspecificcontentSlice.reducer;















