import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../../apiInterceptor";
import { API_URL } from "../../client";

export const updateFolder = createAsyncThunk("updateFolder", async (data) => {
  try {
    console.log("Add course data is.............", data);
    const res = await api.put(`${API_URL}/content/update-folder`, data);
    console.log("responseUpdatecourse",res.data);
    return res.data;
  } catch (error) {
    return {
      message: error?.response?.data?.error,
      status: error?.response?.status,
    };
  }
});

