import React, { useState } from 'react'
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useDispatch } from "react-redux";
import { getcontent, deleteContent } from "../../services/redux/middleware/addContent";
const DeleteLectureModel = ({ show, handleClose, folderId, selectedLecture }) => {
  const dispatch = useDispatch();
  const [Loading, setLoading] = useState(false);
  const handleDeleteClick = () => {
    console.log("Deleting task with IDs:", folderId, selectedLecture);
    const data = {
      folderId,
      selectedLecture,
    }
    setLoading(true)
    dispatch(deleteContent(data)).then(async (res) => {
      console.log("deleteResponse", res);
      
      handleClose();
      await dispatch(getcontent(folderId));
      

      setLoading(false)
      
    });
    
  };


  return (
    <Modal
      className="modal__main__container"
      show={show}
      onHide={handleClose}
      centered
      backdropClassName="modal-backdrop"
    >
      <ModalBody className="deeletemodel">
        <ModalHeader className="close__image_container-1">
          <div className="close__image_container" onClick={handleClose}>
            <img src="/Images/instagram/cross.svg" alt="Close" />
          </div>
        </ModalHeader>
        <div className="delete__task__modal_container">
          <div className="delete__task__modal__container_1">
            <div className="delete__task__modal__heading_container">
              <img
                className="logo__modal__image"
                src="/Images/instagram/deleteModel.svg"
                alt="images"
              />

              <p className="delete__task_text">Delete Content Library</p>

              <p className="delete__task_text_P">Are you sure you want to delete this library?</p>
            </div>
            <div className="delete__modal__button_container">
              <button
                onClick={handleClose}
                className="delete__modal_cancel_button"
              >
                Cancel
              </button>
              <button className="delete__modal_yes_button" onClick={handleDeleteClick}>
                {Loading ? (
                  <div className="loader">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                ) : (
                  <p>Yes I’m Sure</p>
                )}
              </button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default DeleteLectureModel
