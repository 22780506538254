import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import api from "../../apiInterceptor";
import { API_URL } from "../../client";

export const deleteFolder = createAsyncThunk("deleteFolder", async (data) => {
  try {
    console.log("deleteFolder data is.............", data);
    const res = await api.delete(`${API_URL}/content/delete-folder/${data}`, );
    console.log("responseAddcourse",res.data);
    return res.data;
  } catch (error) {
    return {
      message: error?.response?.data?.error,
      status: error?.response?.status,
    };
  }
});

