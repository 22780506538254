import React, { useState, useRef, useEffect } from "react";
import "./ContentLibraryCards.css";
import DeleteLectureModel from "../../Components/DeleteLectureModel/DeleteLectureModel";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate, useLocation } from "react-router-dom";
import { getcontent } from "../../services/redux/middleware/addContent";
import ScreenLoader from "../../Components/ScreenLoader/ScreenLoader"
const ContentLibraryCards = () => {
  const location = useLocation();
  const getQueryParams = (param) => {
    const urlParams = new URLSearchParams(location.search);
    return urlParams.get(param);
  };
  const folderid = getQueryParams('folderid');
  console.log("folderid", folderid);
  const dispatch = useDispatch();
  const videoRefs = useRef([]);
  const [visibleVideos, setVisibleVideos] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedLecture, setSelectedLecture] = useState(null);
  const navigate = useNavigate();
  const handlePlayVideo = (index) => {
    setVisibleVideos((prev) => ({ ...prev, [index]: true }));
    const video = videoRefs.current[index];
    if (video) {
      video.play();
    }
  };
  const handleDeleteClick = (id) => {
    setSelectedLecture(id);
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedLecture(null);
  };
  const handleaddcontent = () => {
    console.log("aaaa", folderid)
    navigate(`/InfoContentLibrary?folderid=${folderid}`);
  }
  const[contentDetail,setcontentDetail]=useState([]);
  const contentdata = useSelector(
    (state) => state?.getcontent?.profile?.data?.contents);

  console.log("contentdata", contentdata)
  useEffect(() => {
    if(contentdata){
      setcontentDetail(contentdata);
    }
  }, [contentdata])
  
  useEffect(() => {
    const fetchFolder = async () => {
      setIsLoading(true);
      console.log(folderid);
      await dispatch(getcontent(folderid));
      setIsLoading(false);
    };
    fetchFolder();
  }, []);
  const handleeditclick = (id) => {
    console.log("going to edit");
    console.log(id, folderid);
    navigate(`/Editcontent/${folderid}/${id}`);
  }
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
    return date.toLocaleString('en-US', options);
  };
  return (
    <>
      {isLoading ? (
        <ScreenLoader />
      ) : (
        <div className="Contentc">
          <div className="addcontentbtndiv" >
            <p> </p>
            <button className="addcontentbtn" onClick={handleaddcontent}>
              <img src="/Images/AddCourse/plus.svg" alt="" className="plusi" />
              Add Content
            </button>
          </div>
          <div className="containerr">
            {contentdata?.length > 0 ?(
            <>
              {contentDetail?.map((item, index) => (
                <div key={index} className="itemm" tabindex="0">
                  {item?.fileType?.startsWith("image") ? (
                    <img src={item?.image} alt="Item" className="image" />
                  ) : (
                    <div className="videowrapper1">
                      {visibleVideos[index] ? (
                        <video
                          src={item?.image}
                          className="videos"
                          ref={(el) => (videoRefs.current[index] = el)}
                          controls
                          autoPlay
                        />
                      ) : (
                        <div className="play-button">
                          {/* <img
                          src={item?.image}
                          alt="Video Thumbnail"
                          className="thumbnail-image"
                        /> */}
                          <button
                            className="playB"
                            onClick={() => handlePlayVideo(index)}
                          >
                            <img src="/Images/instagram/play.svg" alt="play" />
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                  <div className="ContentLibraryCards_div2">
                    <p className="ContentLibraryCards_div2_ptag">{item?.title}</p>
                    {/* <img    className="deleteiconsss" src="/Images/instagram/deleteimg.svg" alt=""
                 onClick={() => handleDeleteClick(index)}/> */}
                    <Dropdown className="ddddrop">
                      <Dropdown.Toggle
                        className="dropdownmain11"
                        variant="success"
                        style={{
                          backgroundColor: "#393C43",
                          borderColor: "#393C43",
                          color: "white",
                        }}
                      >
                        <img
                          className="deleteiconsss"
                          src="/Images/AddCourse/3dotimg.svg"
                          alt=""
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        className="dddropppppp"
                        style={{
                          backgroundColor: "#24262B",
                          padding: "20px",
                          border: "none",
                          borderRadius: "8px",
                        }}
                      >
                        <Dropdown.Item
                          href="#/action-1"
                          style={{
                            color: "white",
                            marginBottom: "15px",
                            padding: "0px",
                          }}
                          className="DropDownmenuOptionDivvvvv"
                          onClick={() => handleeditclick(item?._id)}
                        >
                          <img src="/Images/AddCourse/edit1.svg" alt="" />
                          <p className="DropDownmenuOption">Edit</p>
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="#/action-2"
                          style={{ color: "white", padding: "0px" }}
                          className="DropDownmenuOptionDivvvvv"
                          onClick={() => handleDeleteClick(item?._id)}
                        >
                          <img src="/Images/AddCourse/delete1.svg" alt="" />
                          <p className="DropDownmenuOption">Delete</p>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <p className="ContentLibraryCards_p">
                    {item?.description}
                  </p>
                  <div className="ContentLibraryCards_lastdiv">
                    <img src="/Images/instagram/clock.svg" alt="" />
                    <p className="ContentLibraryCards_lastdivPtag">
                      {item?.createdAt ? formatDate(item?.createdAt) : ''}
                    </p>
                  </div>
                </div>
              ))}
            </>
            ):(
              <div className="empyttytopmain">
              <img src="/Images/instagram/bigfolder.svg" alt="nn" />
              <p className="emptytexttt">No Content added yet!</p>
            </div>
            )}
          </div>
          <ToastContainer />
          {/* Delete Lecture Modal */}
          <DeleteLectureModel show={showModal} folderId={folderid} selectedLecture={selectedLecture} handleClose={handleCloseModal} />
        </div>
      )}
    </>
  );
};
export default ContentLibraryCards;






