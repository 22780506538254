import React, { useState, useEffect } from "react";
// import { useLocation } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { DropzoneArea } from "material-ui-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import "./addCourse.css";
import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Videoloader from "../../../Components/videoloader/ScreenLoader"
import {
  addCourse,
  Thumbnailimage,
  uploadvideo,
} from "../../../services/redux/middleware/addCourse";
import ScreenLoader from "../../../Components/ScreenLoader/ScreenLoader";
function AddCourse() {
  const navigate = useNavigate();
  const [selectedItemTarget, setSelectedItemTarget] = useState("Select");
  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState(1000);
  const [isLoading, setIsLoading] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [previewimage, setpreviewimage] = useState();
  // const [courseType, setCourseType] = useState();
  const [whatYouWillLearn, setWhatYouWillLearn] = useState("");
  const [whyTakeThisCourse, setWhyTakeThisCourse] = useState("");
  const [targetAudience, setTargetAudience] = useState("");
  const [thumbnailImage1, setThumbnailImage] = useState();
  const [whoShouldEnroll, setwhoShouldEnroll] = useState();
  const [videoHeight, setVideoHeight] = useState(null);
  const [videoPreviewUrl, setVideoPreviewUrl] = useState(null);
  const [courseDuration, setCourseDuration] = useState("");
  const [videoPreviewUrls, setVideoPreviewUrls] = useState({});
  const location = useLocation();
  const [videoloading, setvideoloading] = useState(false);
  const [loadingStates, setLoadingStates] = useState({});
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get("type");
  // setCourseType(type);
  console.log("type is........", type)
  const [sections, setSections] = useState([
    {
      title: "",
      content: "",
      duration: "",
      subSections: [
        {
          duration: "",
          title: "",
          content: "",
        },
      ],
    },
  ]);
  // const handleSelectTarget = (audience) => {
  //   setTargetAudience(audience);
  //   setSelectedItemTarget(audience);
  //       setSelectedItemTarget(item);
  //   setExpanded(false);
  // };
  const handleSelectTarget = (item) => {
    setTargetAudience(item);
    setSelectedItemTarget(item);
    setExpanded(false);
  };

  const [expanded, setExpanded] = useState(false);



  const addSection = () => {
    setSections((prev) => [
      ...prev,
      {
        title: "",
        content: "",
        duration: "",
        subSections: [
          {
            duration: "",
            title: "",
            content: "",
          },
        ],
      },
    ]);
  };
  const modules = {
    toolbar: [
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    ]
  };


  const addSubSection = (sectionIndex) => {
    setSections((prev) => {
      const updatedSections = prev.map((section, idx) => {
        if (idx === sectionIndex) {
          return {
            ...section,
            subSections: [
              ...section.subSections,
              {
                duration: "",
                title: "",
                content: ""
              },
            ],
          };
        }
        return section;
      });
      return updatedSections;
    });
  };
  const deleteSubSection = (sectionIndex, subSectionIndex) => {
    setSections((prev) => {
      const updatedSections = [...prev];
      if (subSectionIndex > 0) {
        updatedSections[sectionIndex].subSections.splice(subSectionIndex, 1);
      }
      return updatedSections;
    });
  };

  const deleteSection = (sectionIndex) => {
    setSections((prev) => {
      const updatedSections = [...prev];
      if (updatedSections.length > 1) {
        updatedSections.splice(sectionIndex, 1);
      }
      return updatedSections;
    });
  };

  const handleSectionInputChange = (index, key, value) => {
    setSections((prev) => {
      const updatedSections = [...prev];
      updatedSections[index][key] = value;
      return updatedSections;
    });
  };

  const handleSubSectionInputChange = (
    sectionIndex,
    subSectionIndex,
    key,
    value
  ) => {
    setSections((prev) => {
      const updatedSections = [...prev];
      updatedSections[sectionIndex].subSections[subSectionIndex][key] = value;
      return updatedSections;
    });
  };

  const [url, seturl] = useState();
  const [check, setcheck] = useState(false);


  // const handleVideoChange = (sectionIndex, subSectionIndex = null, files) => {
  //   if (!files || !(files instanceof Blob)) {
  //     console.error("Invalid file:", files);
  //     return;
  //   }

  //   console.log("video", files);
  //   setIsLoading(true);
  //   const videoURL = URL.createObjectURL(files);

  //   const previewKey = subSectionIndex !== null
  //     ? `${sectionIndex}_${subSectionIndex}`
  //     : `section_${sectionIndex}`;

  //   setVideoPreviewUrls((prev) => ({
  //     ...prev,
  //     [previewKey]: videoURL,
  //   }));

  //   console.log("Files received:", files);
  //   dispatch(uploadvideo(files))
  //     .then((res) => {
  //       console.log("Dispatching", res?.payload?.fileUrl);
  //       seturl(res?.payload?.fileUrl);
  //       setcheck(!check);
  //       setIsLoading(false);

  //       // Set the pending update for either section or subsection
  //       setPendingUpdate({ sectionIndex, subSectionIndex });
  //     })
  //     .catch((error) => {
  //       console.error("Upload error:", error);
  //       setIsLoading(false);
  //     });
  // };

  const handleVideoChange = (sectionIndex, subSectionIndex = null, files) => {
    if (!files || !(files instanceof Blob)) {
      console.error("Invalid file:", files);
      return;
    }

    console.log("video", files);
    // setIsLoading(true);

    const videoURL = URL.createObjectURL(files);

    const previewKey = subSectionIndex !== null
      ? `${sectionIndex}_${subSectionIndex}`
      : `section_${sectionIndex}`;

    setLoadingStates((prev) => ({ ...prev, [previewKey]: true }));
    setVideoPreviewUrls((prev) => ({
      ...prev,
      [previewKey]: videoURL,
    }));

    // Create a temporary video element to get duration
    const videoElement = document.createElement("video");
    videoElement.src = videoURL;

    // Wait until the metadata (duration) is loaded
    videoElement.onloadedmetadata = () => {
      const selectedVideoDuration = videoElement.duration;
      const videoHeight = videoElement.videoHeight;
      // setVideoHeight(videoHeight);

      setVideoHeight((prev) => ({
        ...prev,
        [previewKey]: videoHeight,
      }));


      console.log(videoHeight);
      console.log("abcccc", selectedVideoDuration);
      const updatedSections = [...sections];

      if (subSectionIndex !== null) {
        if (updatedSections[sectionIndex] && updatedSections[sectionIndex].subSections[subSectionIndex]) {
          updatedSections[sectionIndex].subSections[subSectionIndex].duration = Math.floor(selectedVideoDuration);
        }
      } else {
        if (updatedSections[sectionIndex]) {
          updatedSections[sectionIndex].duration = Math.floor(selectedVideoDuration);
        }
      }


      setSections(updatedSections);
      // setLoadingStates((prev) => ({ ...prev, [previewKey]: false }));
    };

    console.log("Files received:", files);
    dispatch(uploadvideo(files))
      .then((res) => {
        console.log("Dispatched", res?.payload?.uploadFileResponse?.CloudFrontUrl);
        seturl(res?.payload?.uploadFileResponse?.CloudFrontUrl);
        setcheck(!check);
        setLoadingStates((prev) => ({ ...prev, [previewKey]: false }));
        setvideoloading(false);
        setPendingUpdate({ sectionIndex, subSectionIndex });
      })
      .catch((error) => {
        console.error("Upload error:", error);
        setLoadingStates((prev) => ({ ...prev, [previewKey]: false }));
      });
  };

  const [pendingUpdate, setPendingUpdate] = useState(null);
  useEffect(() => {
    if (pendingUpdate && url) {
      const { sectionIndex, subSectionIndex } = pendingUpdate;

      setSections((prevSections) => {
        const updatedSections = [...prevSections];

        if (subSectionIndex !== null) {

          if (
            updatedSections[sectionIndex] &&
            updatedSections[sectionIndex].subSections[subSectionIndex]
          ) {
            updatedSections[sectionIndex].subSections[subSectionIndex].content = url;
          }
        } else {

          if (updatedSections[sectionIndex]) {
            updatedSections[sectionIndex].content = url;
          }
        }
        return updatedSections;
      });

      setPendingUpdate(null);
    }
  }, [check, pendingUpdate, url]);


  // const isFilled = (value) => {
  //   if (Array.isArray(value)) {
  //     return value.every(isFilled);
  //   } else if (typeof value === "object" && value !== null) {
  //     return Object.values(value).every(isFilled);
  //   } else {
  //     return value !== null && value !== undefined && value !== "";
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
console.log("videoop",thumbnailImage1);
    
    if (!title || !description || !price || !type || !whatYouWillLearn || !whyTakeThisCourse || !whoShouldEnroll || !targetAudience || !thumbnailImage1) {
      toast.warning("Please fill out all fields.", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }

    // Checking sections array for empty fields
    const isSectionsValid = await sections.every((section) => {
      if (!section.title || !section.content || !section.duration) {
        return false;
      }
      return section.subSections.every((subSection) => {
        return subSection.title && subSection.content && subSection.duration;
      });
    });
    setLoading(true);

    console.log("checkfile", isSectionsValid);
    if (!isSectionsValid) {
      setLoading(false);
      toast.warning("Please fill out all section and subsection fields.", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }

    const thumbnailImage = thumbnailImage1;
    const courseType = type;

    const data = {
      title,
      description,
      price,
      courseType,
      whatYouWillLearn,
      whyTakeThisCourse,
      whoShouldEnroll,
      targetAudience,
      thumbnailImage,
      sections,
    };

    // Proceeding with the submission if all fields are valid

    console.log("Data to submit: before dispatching_______________", data);

    dispatch(addCourse(data)).then((res) => {

      if (
        res?.payload?.message ===
        "Course with sections and subsections added successfully"
      ) {
        toast.success("Course added successfully", {
          position: "top-right",
          autoClose: 1800,
        });
        setTimeout(() => {
if(type==="Instagram"){
          navigate("/InstagramCourses");
        }else{
          navigate("/TwitterCourses");
        }
          setLoading(false);
        }, 1800);
      }
    });

  };


  const handleImageChange = (files) => {

    if (files[0]) {
      setvideoloading(true);
      const imageUrl = URL.createObjectURL(files[0]);
      setpreviewimage(imageUrl)
      console.log("abc thumb image for usman badshs", files[0]);

      dispatch(Thumbnailimage(files[0])).then((res) => {
        setThumbnailImage(res?.payload?.uploadFileResponses?.CloudFrontUrl);
        console.log("imageresponse", res?.payload?.uploadFileResponses?.CloudFrontUrl);
        setvideoloading(false);

      }).catch((error) => {
        console.error("Upload error:", error);
        setvideoloading(false);
      });

    }

  };
  const handleCancelClick = () => {
    navigate(-1);
  };
  return (
    <>
      {/* {isLoading ? (
        <ScreenLoader />
      ) : ( */}
      <div className="add__course__main_container">
        <p className="general__course__text">General Course Info</p>

        <div>
          <div className="add__course__container">
            <div className="add__course__input_container">
              <p className="add__course__inputlabel">Title</p>
              <input
                className="add__course__input_placeholder"
                type="text"
                placeholder="Enter Title"
                value={title}
                onChange={(e) => {
                  if (e.target.value.length <= 30) {
                    setTitle(e.target.value);
                  }
                }}
              />
            </div>
            <div className="add__course__input_container">
              <p className="add__course__inputlabel">Description</p>
              <ReactQuill
                className="add__course__input_placeholder_description"
                placeholder="Enter Description"
                value={description}
                modules={modules}
                onChange={(value) => {
                  // if (value?.length <= 200) {
                  setDescription(value);
                  // }
                }}
              />
            </div>
            <div className="add__course__input_container">
              <p className="add__course__inputlabel">What You'll Learn</p>
              <ReactQuill
                className="add__course__input_placeholder_description"
                value={whatYouWillLearn}
                modules={modules}
                onChange={(value) => {
                  // if (value?.length <= 200) {
                  setWhatYouWillLearn(value);
                  // }
                }}

                placeholder="Enter"
                style={{
                  border: 'none',
                }}
              />
            </div>
            <div className="add__course__input_container">
              <p className="add__course__inputlabel">Why Take This Course</p>

              <ReactQuill
                className="add__course__input_placeholder_description"
                placeholder="Enter"
                value={whyTakeThisCourse}
                modules={modules}
                onChange={(value) => {
                  // if (value?.length <= 200) {
                  setWhyTakeThisCourse(value);
                  // }
                }}

              />
            </div>


            <div className="add__course__input_container">
              <p className="add__course__inputlabel">Who should enroll</p>

              <ReactQuill
                className="add__course__input_placeholder_description"
                placeholder="Enter"
                value={whoShouldEnroll}
                modules={modules}
                onChange={(value) => {
                  // if (value?.length <= 200) {
                  setwhoShouldEnroll(value);
                  // }
                }}
              />
            </div>
            <div className="add__course__input_container">
              <p className="add__course__inputlabel">Target Audience</p>
              {/* <Dropdown>
                  <Dropdown.Toggle
                    className="dropdown__main__container_inner"
                    id="dropdown-basic"
                    style={{
                      outline: 'none',
                      boxShadow: 'none',
                      backgroundColor: '#24262B',
                    }}
                  >
                    <span className="dropdown__text__1">
                      {selectedItemTarget}
                    </span>
                    <img
                      src="/Images/AddCourse/dropdown.svg"
                      alt="Dropdown Icon"
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown__list__container">
                    <Dropdown.Item
                      className="dropdown__item__text"
                      onClick={() => handleSelectTarget("Beginners")}
                    >
                      Beginners
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="dropdown__item__text"
                      onClick={() => handleSelectTarget("Professionals")}
                    >
                      Professionals
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="dropdown__item__text_1"
                      onClick={() => handleSelectTarget("Advanced")}
                    >
                      Advanced
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> */}

              <Accordion
                expanded={expanded}
                onChange={() => setExpanded(!expanded)}
                style={{
                  outline: "none",
                  boxShadow: "none",
                  backgroundColor: "transparent",
                  color: "#ffffff",
                  borderRadius: "16px",
                  padding: "0px"
                }}
              >
                {/* Accordion Summary - Toggle Button */}
                <AccordionSummary
                  expandIcon={
                    <img
                      src="/Images/AddCourse/dropdown.svg"
                      alt="Dropdown Icon"
                      style={{ width: "16px", height: "16px" }}
                    />
                  }
                  aria-controls="panel-content"
                  id="panel-header"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "20px",
                    background: "#24262B",
                    borderRadius: "16px"
                  }}
                >
                  <Typography className="dropdown__text__1" style={{ fontWeight: "bold" }}>
                    {selectedItemTarget}
                  </Typography>
                </AccordionSummary>


                <AccordionDetails
                  className="dropdown__list__container"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    backgroundColor: "#1f2024",
                  }}
                >
                  <Typography
                    className="dropdown__item__text"
                    style={{ cursor: "pointer", padding: "5px" }}
                    onClick={() => handleSelectTarget("Beginners")}
                  >
                    Beginners
                  </Typography>
                  <Typography
                    className="dropdown__item__text"
                    style={{ cursor: "pointer", padding: "5px" }}
                    onClick={() => handleSelectTarget("Professionals")}
                  >
                    Professionals
                  </Typography>
                  <Typography
                    className="dropdown__item__text_1"
                    style={{ cursor: "pointer", padding: "5px" }}
                    onClick={() => handleSelectTarget("Advanced")}
                  >
                    Advanced
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="add__course__input_container">
              <p className="add__course__inputlabel">Thumbnail Image</p>
              <div className={
                previewimage
                  ? "image__dropzone__mainactive"
                  : "image__dropzone__main"
              }>
                {videoloading ? (
                  <Videoloader />
                ) : (
                  <>
                    <DropzoneArea
                      filesLimit={1}
                      acceptedFiles={["image/*"]}
                      maxFileSize={5000000}
                      showAlerts={["error", "info"]}
                      dropzoneText="Upload Image"
                      onChange={handleImageChange}
                    />

                    {previewimage && (
                      <div style={{ width: "100%" }} className="imageselected">

                        <img
                          src={previewimage}
                          alt="Selected"
                          style={{ width: "100%", borderRadius: "16px", height: "300px", objectFit: "cover" }}
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="">
            {sections.map((section, sectionIndex) => (
              <div
                className="course-sections"
                key={`section-${sectionIndex}`}
              >
                <div className="add__course__container11">
                  <p className="course__section_info_text">
                    Course Section Info
                  </p>
                  <div className="add__course__input_container">
                    <p className="add__course__inputlabel">Section Title</p>
                    <input
                      className="add__course__input_placeholder"
                      type="text"
                      placeholder="Enter Section Title"
                      value={section.title}
                      onChange={(e) => {
                        if (e.target.value.length <= 20) {
                          handleSectionInputChange(
                            sectionIndex,
                            "title",
                            e.target.value
                          )
                        }
                      }}
                    />
                  </div>
                  <div className="add__course__input_container">
                    <p className="add__course__inputlabel">
                      Lecture Video
                    </p>
                    <div
                      className={
                        videoPreviewUrls[`section_${sectionIndex}`]
                          ? "image__dropzone__mainactive"
                          : "image__dropzone__main"
                      }
                    >
                      {loadingStates[`section_${sectionIndex}`] ? (
                        <Videoloader />
                      ) : (
                        <>
                          <DropzoneArea
                            id={`upload_video_section_${sectionIndex}`}
                            acceptedFiles={["video/*"]}
                            maxFileSize={50000000000}
                            showAlerts={["error", "info"]}
                            dropzoneText="Upload Video"
                            onChange={(files) =>
                              handleVideoChange(sectionIndex, null, files[0])
                            }
                            onDropRejected={(files) =>
                              alert("File rejected: " + files[0]?.name)
                            }
                            dropzoneClass="image__dropzone__main"
                          />
                          {videoPreviewUrls[`section_${sectionIndex}`] && (
                            <video
                              controls
                              width="100%"
                              src={videoPreviewUrls[`section_${sectionIndex}`]}
                              className="videopreview_llllinner"
                              style={{ height: videoHeight[`section_${sectionIndex}`] < 1000 ? `auto` : '300px', }}
                            >
                              Your browser does not support the video tag.
                            </video>
                          )}
                        </>
                      )
                      }
                    </div>
                  </div>
                  {sections.length > 1 && (

                    <button
                      className="delete_subsection_button"
                      onClick={() => deleteSection(sectionIndex)}
                    >
                      <div className="delete_subsection_button_container">
                        <img
                          src="/Images/AddCourse/deleteIcon.svg"
                          alt="deleteIcon"
                        />
                        <p className="add_subsection_text">
                          Delete Section
                        </p>
                      </div>
                    </button>
                  )}
                </div>

                <div className="subsections-container">
                  {section.subSections.map((subSection, subIndex) => (
                    <>
                      <div
                        className="add__course__container"
                        key={`subSection-${sectionIndex}-${subIndex}`}
                      >
                        <p className="course__section_info_text">
                          Sub Section {subIndex + 1}
                        </p>
                        <div className="add__course__input_container">
                          <p className="add__course__inputlabel">
                            Sub Section Title
                          </p>
                          <input
                            className="add__course__input_placeholder"
                            type="text"
                            placeholder="Enter Sub Section Title"
                            value={subSection.title}
                            onChange={(e) => {
                              if (e.target.value.length <= 20) {
                                handleSubSectionInputChange(
                                  sectionIndex,
                                  subIndex,
                                  "title",
                                  e.target.value
                                )
                              }
                            }}
                          />
                        </div>
                        <div className="add__course__input_container">
                          <p className="add__course__inputlabel">
                            Lecture Video
                          </p>
                          <div className={
                            videoPreviewUrls[
                              `${sectionIndex}_${subIndex}`
                            ]
                              ? "image__dropzone__mainactive"
                              : "image__dropzone__main"
                          }>
                            {/* //{subSection.content[0] instanceof File ? ( */}
                            {/* // <video */}
                            {/* //   src={URL.createObjectURL(subSection.content[0])}
                          //   controls
                          //   className="uploaded-video"
                          // />
                        // ) : ( */}

                            {loadingStates[`${sectionIndex}_${subIndex}`] ? (
                              <Videoloader />
                            ) : (
                              <>
                                <DropzoneArea
                                  id={`upload_video_${sectionIndex}_${subIndex}`}
                                  acceptedFiles={["video/*"]}
                                  maxFileSize={50000000000}
                                  showAlerts={["error", "info"]}
                                  dropzoneText="Upload Video"
                                  onChange={(files) =>
                                    handleVideoChange(
                                      sectionIndex,
                                      subIndex,
                                      files[0]
                                    )
                                  }
                                  onDropRejected={(files) =>
                                    alert("File rejected: " + files[0]?.name)
                                  }
                                  dropzoneClass="image__dropzone__main"
                                />
                                {/* )} */}

                                {videoPreviewUrls[
                                  `${sectionIndex}_${subIndex}`
                                ] && (
                                    <video
                                      controls
                                      width="100%"
                                      src={
                                        videoPreviewUrls[
                                        `${sectionIndex}_${subIndex}`
                                        ]
                                      }
                                      className="videopreview_llllinner"
                                      style={{
                                        height: videoHeight[
                                          `${sectionIndex}_${subIndex}`
                                        ] < 1000 ? `auto` : '300px',
                                      }}
                                    >
                                      Your browser does not support the video tag.
                                    </video>
                                  )}
                              </>
                            )
                            }
                          </div>
                        </div>
                        {subIndex > 0 && (
                          <button
                            className="delete_subsection_button"
                            onClick={() =>
                              deleteSubSection(sectionIndex, subIndex)
                            }
                          >
                            <div className="delete_subsection_button_container">
                              <img
                                src="/Images/AddCourse/deleteIcon.svg"
                                alt="deleteIcon"
                              />
                              <p className="add_subsection_text">
                                Delete Sub Section
                              </p>
                            </div>
                          </button>
                        )}

                      </div>

                    </>
                  ))}
                  <button
                    className="add_subsection_button"
                    onClick={() => addSubSection(sectionIndex)}
                  >
                    <div className="add_subsection_button_container">
                      <img
                        src="/Images/AddCourse/addicon.svg"
                        alt="addIcon"
                      />
                      <p className="add_subsection_text">
                        Add Sub Section
                      </p>
                    </div>
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
        <button className="add__section__button" onClick={addSection}>
          <div className="add_subsection_button_container">
            <img src="/Images/AddCourse/addicon.svg" alt="addIcon" />
            <p className="add_subsection_text">Add Section</p>
          </div>
        </button>
        <div className="publish__button__main__container">
          <button onClick={handleSubmit} className="publish___button_main" disabled={Loading}>
            {Loading ? (
              <div className="loader">
                <div></div>
                <div></div>
                <div></div>
              </div>
            ) : (
              <p>Publish</p>
            )}

          </button>
          <button
            onClick={handleCancelClick}
            className="cancel___button_main"
          >
            Cancel
          </button>
        </div>
      </div>
      {/* )} */}
      <ToastContainer autoClose={10000}
        position="top-right" />
    </>
  );
}

export default AddCourse;