import "./Drawer.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Header from "../Header/Header";
// import useAuth from "../../hooks/Web3Connection/useAuth";
// import Web3Modal from "../modal/Web3modal/Web3Modal";
import Avatar from "@mui/material/Avatar";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
// import { useWeb3React } from "@web3-react/core";
import {
  StyledEngineProvider,
  ThemeProvider,
  createTheme,
} from "@mui/material/styles";
import { Menu, MenuItem, makeStyles } from "@mui/material";
// import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
// import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";

import { useMediaQuery } from "react-responsive";
import { borderBottom, borderRight, display, minHeight } from "@mui/system";

const drawerWidth = 280;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    height: "100%",
    overflowX: "hidden",
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
      fontFamily: "Montserrat",
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  backgroundColor: "white",
  zIndex: "2",
}));

const AvatarTopDiv = styled("div")(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(1),
  right: theme.spacing(1),
  zIndex: theme.zIndex.drawer + 1,
  [theme.breakpoints.up("md")]: {
    width: "100%",
    minWidth: 768,
  },
  [theme.breakpoints.down("sm")]: {
    top: theme.spacing(1),
    left: theme.spacing(1),
    right: "auto",
    display: "flex",
    alignItems: "center",
    width: "100%",
    justifyContent: "flex-end",

    gap: theme.spacing(2),
  },
}));
const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),

  ...theme.mixins.toolbar,
  justifyContent: "space-between",
  padding: "26px 24px",
  borderBottom: "none",
  zIndex: "2",
}));

const DrawerFooter = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),

  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));
const themeMui = createTheme({
  typography: {
    fontFamily: '"Montserrat", sans-serif',
  },
});
function getHeaderName(pathname) {
  switch (pathname) {
    case "/InstagramCourses":
      return "Academy";
    case "/TwitterCourses":
      return "Academy";
    case "/ContentLibrary":
      return "Content Library";
    case "/ContentLibraryCards":
      return "Content Library";

    case "/CourseEditing":
      return "Edit course";

    case "/InfoContentLibrary":
      return "Add Content";


    case "/AddCourse":
      return "Add Course";
    case "/addGroup":
      return "Add Groups";
    case "/Editcontent":
      return "Edit Content";
    default:
      return "Dashboard";
  }
}
export default function SideDrawer({ children, showSidebar, PageName }) {
  console.log("i am calling again and again")
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedSubItem, setSelectedSubItem] = useState(0);
  const [active, setactive] = useState("");
  const handle_accordian = (abc) => {
    setactive(active === abc ? "" : abc);
  };

  const handleSubItemClick = (index) => {
    console.log("clicked", index);
    setSelectedItem(null);
    // setSelectedSubItem(index);
    if (window.innerWidth < 1030) {
      setOpen(false);
      setMobileOpen(prevState => !prevState);
    }
  };
  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const theme = useTheme();
  const isMobile = useMediaQuery({
    query: "(max-width: 1064px)",
  });

  useEffect(() => {
    if (isMobile) {
      setOpen(false);
    }
  }, [isMobile]);
  const [selectedItem, setSelectedItem] = useState(0);
  const [selectedotherItem, setSelectedOtherItem] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);


  const handleListItemClick = (index, text) => {
    console.log("clicked", index);
    setSelectedSubItem(null);
    setSelectedOtherItem(null);
    setSelectedItem(index);

    if (text === "Skainet Academy") {
      setIsDropdownOpen(!isDropdownOpen);
    } else {
      setIsDropdownOpen(false);
    }
  };
  const handleOtherListItemClick = (index) => {
    console.log("clicked", index);
    setSelectedItem(null);
    setSelectedOtherItem(index);
  };
  const handleClickOutsideDrawer = (event) => {

    if (event.target.classList.contains("drawer-overlay")) {
      handleDrawerClose();
    }
  };

  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    console.log("this is clicked");
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setMobileOpen(!mobileOpen);
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const opens = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    console.log("closing");
  };

  const handleLogout = () => {
    navigate('/');
  };
  const handleLogoutMain = () => {
    navigate("/");
    localStorage.clear();
    localStorage.removeItem('isAuthenticated');
  };

  const handleSignIn = () => {
    localStorage.clear();
    navigate("/");
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const menuItems = [
    {
      text: "Skainet Academy",
      path: "/InstagramCourses",
      disabled: false,

    },
    { text: "Lead Generator", disabled: false },
    // { text: "Account", path: "/Dashboard/UserProfile", disabled: false },
    { text: "Content Library", path: "/ContentLibrary", disabled: false },
    { text: "SMM Automated", disabled: false },

    // { text: "Partner Program", path: "/Dashboard/Admin", disabled: false },
  ];
  const dashicons = [
    "/Images/Dashboard/dimage.svg",
    "/Images/Dashboard/staking.svg",
    "/Images/Dashboard/Airdrops.svg",
    "/Images/Dashboard/pp.svg",
    // "/Images/Dashboard/wealth.svg",
    // "/Images/Dashboard/latestnews.svg",
    // "/Images/Dashboard/Airdrops.svg",
  ];
  const activeDashicons = [
    "/Images/Dashboard/dimage.svg",
    "/Images/Dashboard/staking.svg",
    "/Images/Dashboard/Airdrops.svg",
    "/Images/Dashboard/pp.svg",
  ];
  const OthermenuItems = [{}, {}];
  const otherdashicons = ["", ""];

  let users = JSON.parse(localStorage.getItem("user"));

  let filteredMenuItems = [];
  let filteredOtherMenuItems = [];

  if (users?.role === 1) {
    filteredMenuItems = menuItems.filter((item) =>
      ["Home", "File Manager", "Admin"].includes(item.text)
    );
  } else {
    filteredMenuItems = menuItems.filter((item) => item.text !== "Admin");
    filteredOtherMenuItems = OthermenuItems.filter(
      (item) => item.text !== "Admin"
    );
  }

  // for connect wallet
  const [wallet, SetWallet] = useState(false);
  // const { account, chainId } = useWeb3React();
  // const { logout } = useAuth();
  const connectorId =
    typeof window !== "undefined" ? localStorage.getItem("connectorId") : null;
  async function handleConnect() {
    // if (!account) {
    //   SetWallet(true);
    // } else {
    //   await logout(connectorId);
    //   localStorage.clear();
    // }
  }
  useEffect(() => {
    const pathToActive = {
      '/TwitterCourses': 0,
      '/InstagramCourses': 0,
      '/ContentLibrary': 2,
      '/AddCourse': 0,
    };
    setSelectedItem(pathToActive[location.pathname]);
    console.log("selected", selectedItem);
  }, [handleListItemClick]);
  useEffect(() => {
    const pathToActive = {
      '/TwitterCourses': 2,
      '/InstagramCourses': 1,
      '/CourseEditing': 1,

    };
    const currentPath = location.pathname;
    if (currentPath.startsWith('/ContentLibraryCards') || currentPath.startsWith('/InfoContentLibrary') || currentPath.startsWith('/Editcontent')) {
      setSelectedItem(2);
    }
    if (pathToActive[currentPath] !== undefined) {
      setSelectedSubItem(pathToActive[currentPath]);
    } else {
      const queryParams = new URLSearchParams(location.search);
      const type = queryParams.get('type');

      if (type === 'Instagram') {
        setSelectedSubItem(1);
      } else if (type === 'Twitter') {
        setSelectedSubItem(2);
      }
    }

    console.log("selected", selectedSubItem);
  }, [location.pathname, location.search, handleListItemClick]);

  useEffect(() => {
    const isMobile = window.matchMedia("(max-width: 768px)").matches;
    if (open && isMobile) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [open]);
  return (
    <>
      <ThemeProvider theme={themeMui}>
        {showSidebar && (
          <Box
            sx={{
              paddingLeft: open
                ? isMobile
                  ? "0px" // Padding when the drawer is open on mobile
                  : `${drawerWidth}px` // Padding when the drawer is open on larger screens
                : isMobile
                  ? "0px" // Padding when the drawer is closed on mobile
                  : "28px", // Padding when the drawer is closed on larger screens
              transition: "padding-left 0.3s ease", // Smooth transition when opening/closing drawer
            }}
          >
            <CssBaseline />

            <AppBar
              position="fixed"
              open={open}
              style={{
                padding: "12px 32px",
                boxShadow: "none",
                borderBottom: "1px solid #24262b",
                backgroundColor: "#111214",
              }}
              className="sidebar__top-main"
            >
              <Toolbar
                style={{
                  display: "flex",
                  position: "relative",
                  zIndex: "2",
                  padding: "0px",
                  minHeight: "59px",
                }}
                sx={{
                  borderBottom: "none",
                }}
              >
                <IconButton
                  color="#0000"
                  aria-label="open drawer"
                  onClick={isMobile ? handleDrawerToggle : handleDrawerOpen}
                  edge="start"
                  sx={{
                    mr: 2,
                    ...(open && { display: "none" }),
                    position: "absolute",
                    color: "#FFFFFF",
                    zIndex: 999,
                  }}
                >
                  <MenuIcon
                    style={{
                      position: "relative",
                      zIndex: 999,
                    }}
                    className="toggle"
                    color={"black"}
                  />
                </IconButton>

                <div style={{ padding: "0px" }}>
                  <AvatarTopDiv
                    style={{ zIndex: 1, width: "100%", right: "0",justifyContent:"flex-start" }}
                  >
                    <div
                      id="basic-button"
                      className="Avatar-top-div"
                      aria-controls={opens ? "basic-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={opens ? "true" : undefined}
                    >
                      <div>
                        <p>
                          <Header
                            headername={getHeaderName(location.pathname)}
                          />
                        </p>
                      </div>
                      {/* <div className="notify-search">
                        

                        <img src="/Images/Dashboard/notify.svg" alt="/" />
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "12px",
                          }}
                        >
                          <Avatar
                         
                            alt="Profile Picture"
                            src="/Images/Dashboard/Profile.svg"
                            sx={{ width: 40.5, height: 40.5 }}
                            className="avatar-img"
                          />
                        </div>
                      </div> */}

                      <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={opens}
                        onClose={handleClose}
                        MenuListProps={{
                          "aria-labelledby": "basic-button",
                        }}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      ></Menu>
                    </div>
                  </AvatarTopDiv>
                </div>
                {/* <Typography variant="h6" noWrap component="div">
            Persistent drawer
          </Typography> */}
              </Toolbar>
            </AppBar>
            {!isMobile && (
              <Drawer
                PaperProps={{
                  sx: {
                    backgroundColor: "#111214",
                    borderRight: "1px solid #24262b",
                    display: "flex",
                    justifyContent: "space-between",
                  },
                }}
                style={{ zIndex: 1, position: "relative" }}
                sx={{
                  width: drawerWidth,
                  flexShrink: 0,

                  "& .MuiDrawer-paper": {
                    width: drawerWidth,
                    boxSizing: "border-box",
                  },
                }}
                variant="persistent"
                anchor="left"
                open={open}
              >
                <div>
                  <DrawerHeader>
                    <img
                      src="/Images/Dashboard/logo.svg"
                      // width={"30px"}
                      // height={"30px"}
                      className="logo__container1"
                      alt="Logo"
                    />
                    <IconButton
                      onClick={handleDrawerClose}
                      style={{ color: "#000" }}
                    >
                      {theme.direction === "ltr" ? (
                        <ChevronLeftIcon style={{ color: "#FFFFFF" }} />
                      ) : (
                        <ChevronRightIcon style={{ color: "#FFFFFF" }} />
                      )}
                    </IconButton>
                  </DrawerHeader>
                  {/* <Divider /> */}
                  <List className="List-div">
                    {filteredMenuItems?.map(
                      ({ text, path, disabled }, index) => (
                        <React.Fragment key={index}>
                          <ListItem
                            selected={index === selectedItem}
                            disabled={disabled}
                            disablePadding
                            sx={{
                              fontSize: "14px",
                              fontWeight: "500",
                              lineHeight: "22.4px",
                            }}
                          >
                            <ListItemButton
                              component={Link}
                              to={path}
                              onClick={() => handleListItemClick(index, text)}
                              selected={index === selectedItem}
                              sx={{
                                background:
                                  index === selectedItem
                                    ? "#393C43 !important"
                                    : "transparent",
                                color:
                                  index === selectedItem
                                    ? "#FFFFFF !important"
                                    : "#FFFFFF",
                                border:
                                  index === selectedItem
                                    ? "1px solid #0F6FFF !important"
                                    : "none",
                                "&:hover": {
                                  color: "#FFFFFF ",
                                },
                              }}
                            >
                              <ListItemIcon
                                minWidth={"24px"}
                                sx={{
                                  "& ..MuiListItemIcon-root": {
                                    minWidth: "24px",
                                  },
                                }}
                              >
                                <img
                                  src={
                                    index === selectedItem
                                      ? activeDashicons[index]
                                      : dashicons[index]
                                  }
                                  alt={`Icon ${index + 1}`}
                                />
                              </ListItemIcon>
                              <ListItemText primary={text} />
                            </ListItemButton>
                          </ListItem>

                          {/* Skainet Academy Dropdown */}
                          {text === "Skainet Academy" && isDropdownOpen && (
                            <div
                              style={{ paddingLeft: "30px", marginTop: "5px" }}
                            >
                              <ListItemButton
                                component={Link}
                                to="/InstagramCourses"
                                onClick={() => {
                                  handleSubItemClick(1);
                                  setSelectedItem(
                                    filteredMenuItems.findIndex(
                                      (item) => item.text === "Skainet Academy"
                                    )
                                  );
                                }}
                                sx={{
                                  color:
                                    selectedSubItem === 1
                                      ? "#0F6FFF !important"
                                      : "#FFFFFF",
                                  display: "flex",
                                  gap: "12px",
                                  alignItems: "center",
                                  "&:hover": {
                                    color: "#FFFFFF ",
                                  },
                                }}
                              >
                                <img
                                  src={
                                    selectedSubItem === 1
                                      ? "/Images/Dashboard/subinstablue.svg"
                                      : "/Images/Dashboard/subIconInstagram.svg"
                                  }
                                  alt="Instagram Icon"
                                />
                                <ListItemText primary="Instagram" />
                              </ListItemButton>
                              <ListItemButton
                                component={Link}
                                to="/TwitterCourses"
                                onClick={() => {
                                  handleSubItemClick(2);
                                  setSelectedItem(
                                    filteredMenuItems.findIndex(
                                      (item) => item.text === "Skainet Academy"
                                    )
                                  );
                                }}
                                sx={{
                                  color:
                                    selectedSubItem === 2
                                      ? "#0F6FFF !important"
                                      : "#FFFFFF",
                                  display: "flex",
                                  gap: "12px",
                                  alignItems: "center",
                                  "&:hover": {
                                    color: "#FFFFFF",
                                  },
                                }}
                              >
                                <img
                                  src={
                                    selectedSubItem === 2
                                      ? "/Images/Dashboard/twittersub.svg"
                                      : "/Images/Dashboard/subIconTwitter.svg"
                                  }
                                  alt="Twitter Icon"
                                />
                                <ListItemText style={{ fontSize: "14px", fontWeight: "500" }} primary="Twitter" />
                              </ListItemButton>
                            </div>
                          )}
                        </React.Fragment>
                      )
                    )}
                  </List>

                  {/* <Divider /> */}
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => handleLogoutMain()}
                  className="Logout-div"
                >
                  <img src="/Images/Dashboard/logout.svg" alt="Logout-icon" />

                  <p className="Logout-txt">Logout</p>
                </div>
              </Drawer>
            )}

            {isMobile && (
              <>

                <Drawer
                  PaperProps={{
                    sx: {
                      backgroundColor: "#101214",
                    },
                  }}
                  sx={{
                    width: 250,
                    flexShrink: 0,
                    "& .MuiDrawer-paper": {
                      width: drawerWidth,
                      boxSizing: "border-box",
                    },
                  }}
                  variant="persistent"
                  anchor="left"
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                >
                  <div
                    className="drawer-overlay"
                    style={{
                      position: "fixed",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                    onClick={handleClickOutsideDrawer}
                  />
                  <div>
                    <DrawerHeader>
                      <img
                        src="/Images/Dashboard/logo.svg"
                        // width={"30px"}
                        // height={"30px"}
                        className="ms-3"
                        alt="Logo"
                      />
                      <IconButton
                        onClick={handleDrawerClose}
                        style={{ color: "#000" }}
                      >
                        {theme.direction === "ltr" ? (
                          <ChevronLeftIcon style={{ color: "#FFFFFF" }} />
                        ) : (
                          <ChevronRightIcon style={{ color: "#FFFFFF" }} />
                        )}
                      </IconButton>
                    </DrawerHeader>
                    {/* <Divider /> */}
                    <List className="List-div">
                      <p className="items-main-heading">General Menu</p>
                      {filteredMenuItems?.map(
                        ({ text, path, disabled }, index) => (
                          <React.Fragment key={index}>
                            <ListItem
                              selected={index === selectedItem}
                              disabled={disabled}
                              disablePadding
                              sx={{
                                fontSize: "14px",
                                fontWeight: "500",
                                lineHeight: "22.4px",
                              }}
                            >
                              <ListItemButton
                                component={Link}
                                to={path}
                                onClick={() => handleListItemClick(index, text)}
                                selected={index === selectedItem}
                                sx={{
                                  background:
                                    index === selectedItem
                                      ? "#393C43 !important"
                                      : "transparent",
                                  color:
                                    index === selectedItem
                                      ? "#FFFFFF !important"
                                      : "#FFFFFF",
                                  border:
                                    index === selectedItem
                                      ? "1px solid #0F6FFF !important"
                                      : "none",
                                  "&:hover": {
                                    color: "#FFFFFF ",
                                  },
                                }}
                              >
                                <ListItemIcon
                                  minWidth={"24px"}
                                  sx={{
                                    "& ..MuiListItemIcon-root": {
                                      minWidth: "24px",
                                    },
                                  }}
                                >
                                  <img
                                    src={
                                      index === selectedItem
                                        ? activeDashicons[index]
                                        : dashicons[index]
                                    }
                                    alt={`Icon ${index + 1}`}
                                  />
                                </ListItemIcon>
                                <ListItemText primary={text} />
                              </ListItemButton>
                            </ListItem>

                            {/* Skainet Academy Dropdown */}
                            {text === "Skainet Academy" && isDropdownOpen && (
                              <div
                                style={{
                                  paddingLeft: "30px",
                                  marginTop: "5px",
                                }}
                              >
                                <ListItemButton
                                  component={Link}
                                  to="/InstagramCourses"
                                  onClick={() => {
                                    handleSubItemClick(1);
                                    setSelectedItem(
                                      filteredMenuItems.findIndex(
                                        (item) =>
                                          item.text === "Skainet Academy"
                                      )
                                    );
                                  }}
                                  sx={{
                                    color:
                                      selectedSubItem === 1
                                        ? "#0F6FFF !important"
                                        : "#FFFFFF",
                                    display: "flex",
                                    gap: "12px",
                                    alignItems: "center",
                                    "&:hover": {
                                      color: "#FFFFFF ",
                                    },
                                  }}
                                >
                                  <img
                                    src={
                                      selectedSubItem === 1
                                        ? "/Images/Dashboard/subinstablue.svg"
                                        : "/Images/Dashboard/subIconInstagram.svg"
                                    }
                                    alt="Instagram Icon"
                                  />
                                  <ListItemText primary="Instagram" />
                                </ListItemButton>
                                <ListItemButton
                                  component={Link}
                                  to="/TwitterCourses"
                                  onClick={() => {
                                    handleSubItemClick(2);
                                    setSelectedItem(
                                      filteredMenuItems.findIndex(
                                        (item) =>
                                          item.text === "Skainet Academy"
                                      )
                                    );
                                  }}
                                  sx={{
                                    color:
                                      selectedSubItem === 2
                                        ? "#0F6FFF !important"
                                        : "#FFFFFF",
                                    display: "flex",
                                    gap: "12px",
                                    alignItems: "center",
                                    "&:hover": {
                                      color: "#FFFFFF",
                                    },
                                  }}
                                >
                                  <img
                                    src={
                                      selectedSubItem === 2
                                        ? "/Images/Dashboard/twittersub.svg"
                                        : "/Images/Dashboard/subIconTwitter.svg"
                                    }
                                    alt="Twitter Icon"
                                  />
                                  <ListItemText primary="Twitter" />
                                </ListItemButton>
                              </div>
                            )}
                          </React.Fragment>
                        )
                      )}
                    </List>
                  </div>
                  <div
                    style={{ cursor: "pointer",zIndex:1 }}
                    onClick={handleLogoutMain}
                    className="Logout-div"
                  >
                    <img src="/Images/Dashboard/logout.svg" alt="Logout-icon" />

                    <p className="Logout-txt">Logout</p>
                  </div>
                </Drawer>
              </>
            )}

            <Main
              open={open}
              sx={
                {
                  // paddingLeft: isMobile ? "50px" : "28px",
                }
              }
            >
              {/* <DrawerHeader /> */}
              {children}
            </Main>
          </Box>
        )}
      </ThemeProvider>
      {/* <Web3Modal show={wallet} setModalShows={SetWallet} /> */}
    </>
  );
}
