import React, { useEffect, useState } from "react";
import { DropzoneArea } from "material-ui-dropzone";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "./Editcontent.css";
import { getSpecificContent, updateContent } from "../../services/redux/middleware/addContent";
import { ToastContainer, toast } from 'react-toastify';
import ScreenLoader from "../../Components/ScreenLoader/ScreenLoader";
import Videoloader from "../../Components/videoloader/ScreenLoader"
const Editcontent = () => {
    const dispatch = useDispatch();
    const [thumbnailImage, setThumbnailImage] = useState(null); // For selected thumbnail
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [caption1, setCaption1] = useState("");
    const [caption2, setCaption2] = useState("");
    const [caption3, setCaption3] = useState("");
    const location = useLocation();
    const navigate = useNavigate();
    const { folderid, id } = useParams();
    const contentData = useSelector(
        (state) => state?.getSpecificContent?.profile
    );
    const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
    const [videoPreviewUrl, setVideoPreviewUrl] = useState(null);
    const [videoloading, setvideoloading] = useState(false);
    // Initialize form fields from fetched content data
    useEffect(() => {
        if (contentData) {
            setTitle(contentData?.title || "");
            setDescription(contentData?.description || "");
            setCaption1(contentData?.caption1 || "");
            setCaption2(contentData?.caption2 || "");
            setCaption3(contentData?.caption3 || "");
            if (contentData?.fileType?.startsWith("video")) {
                setVideoPreviewUrl(contentData?.image || "");
            } else {
                setImagePreviewUrl(contentData?.image || "");
            }
            setThumbnailImage(contentData?.image || "");
        }
    }, [contentData]);


    const handleImageChange = (event) => {
        console.log("function is calling");
        const selectedFile = event.target.files[0];
        console.log("selected media", selectedFile);

        if (selectedFile) {
            setvideoloading(true);
            setThumbnailImage(selectedFile); // Set the selected image or video

            setTimeout(() => {
                setvideoloading(false);
            }, 2000);

            if (selectedFile.type.startsWith("image/")) {
                setImagePreviewUrl(URL.createObjectURL(selectedFile));
                setVideoPreviewUrl(null);
            } else if (selectedFile.type.startsWith("video/")) {
                setVideoPreviewUrl(URL.createObjectURL(selectedFile));
                setImagePreviewUrl(null);
            }
        }
    };
    //hhhhhhh
    // Fetch specific content on mount
    useEffect(() => {
        setIsLoading(true);
        const data = { folderid, id };
        dispatch(getSpecificContent(data)).finally(() => setIsLoading(false));
    }, [dispatch, folderid, id]);
    const handlePublish = () => {
        if (!title || !description || !caption1 || !caption2 || !caption3 || !thumbnailImage) {
            toast.warn("Please fill all fields", {
                position: "top-right",
                autoClose: 3000,
            });
        } else {
            setLoading(true);
            const data = {
                title,
                description,
                caption1,
                caption2,
                caption3,
                thumbnailImage,
            };
            const dataWithId = { data, folderid, id };
            dispatch(updateContent(dataWithId)).then((res) => {
               
                const message = res?.payload?.message;
                if (message?.startsWith("Content updated successfully")) {
                    toast.success("Content updated successfully!", {
                        position: "top-right",
                        autoClose: 3000,
                    });
                    
                } else {
                    toast.error("Can't update right now!", {
                        position: "top-right",
                        autoClose: 3000,
                    });
                    
                }
                // setLoading(false);
                setTimeout(() => {
                    navigate(-1);
                    setLoading(false);
                }, 3000);
            });
        }
    };
    return (
        // <>
        //     {isLoading ? (
        //         <ScreenLoader />
        //     ) : (
        <div className="info__main_containerr">
            <p className="InfoContentLibraryMain1_p">Content Library Info</p>
            <div className="InfoContentLibraryMain1_div">
                <div className="add__course__input_container">
                    <p className="infocontact__inputlabel">Title</p>
                    <input
                        className="infocontent__input_placeholder"
                        type="text"
                        placeholder="Enter Title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                    />
                </div>
                <div className="add__course__input_container">
                    <p className="infocontact__inputlabel">Description</p>
                    <textarea
                        className="info_content__input_placeholder_description"
                        placeholder="Enter Description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    />
                </div>
                <div className="add__course__input_container">
                    <p className="infocontact__inputlabel">Caption 1</p>
                    <textarea
                        className="info_content__input_placeholder_description"
                        placeholder="Enter"
                        value={caption1}
                        onChange={(e) => setCaption1(e.target.value)}
                    />
                </div>
                <div className="add__course__input_container">
                    <p className="infocontact__inputlabel">Caption 2</p>
                    <textarea
                        className="info_content__input_placeholder_description"
                        placeholder="Enter"
                        value={caption2}
                        onChange={(e) => setCaption2(e.target.value)}
                    />
                </div>
                <div className="add__course__input_container">
                    <p className="add__course__inputlabel">Caption 3</p>
                    <textarea
                        className="add__course__input_placeholder_description"
                        placeholder="Enter"
                        value={caption3}
                        onChange={(e) => setCaption3(e.target.value)}
                    />
                </div>
                <div className="add__course__input_container">
                    <p className="infocontact__inputlabel">Thumbnail Media</p>
                    <div className="image__dropzone__main" style={{ width: imagePreviewUrl || videoPreviewUrl ? "300px" : "100%" }}>
                        {/* <DropzoneArea
                                    filesLimit={1}
                                    acceptedFiles={["image/*", "video/*"]}
                                    maxFileSize={500000000}
                                    dropzoneText="Upload Media"
                                    onChange={handleImageChange}
                                /> */}
                        <label style={{ cursor: "pointer" }}>
                            <input
                                type="file"
                                accept="image/*,video/*"
                                style={{ display: "none" }}
                                onChange={handleImageChange}
                            />
                            <div className="maindropzzz">
                                <img src="/Images/instagram/dropimage.svg" alt="rr" />
                                <span className="add__course__inputlabel"> Upload media</span>
                            </div>
                        </label>

                        {videoloading ? (
                            <Videoloader />
                        ) : (
                            <>
                                {imagePreviewUrl && (
                                    <div className="image-preview-container">
                                        <img
                                            src={imagePreviewUrl}
                                            alt="Selected"
                                            style={{ width: "300px", borderRadius: "16px", height: "300px", objectFit: "cover" }}
                                        />
                                    </div>
                                )}
                                {videoPreviewUrl && (
                                    <div className="video-preview-container" style={{ marginTop: '16px', width: "100%" }}>
                                        <video
                                            src={videoPreviewUrl}
                                            controls
                                            style={{ width: '100%', borderRadius: '16px' }}
                                            className="editvideopreview_llll"
                                        />
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
            <div className="publish__button__main__containerr">
                <button className="publish___buttons_main" onClick={handlePublish} disabled={loading}>
                    {loading ? (
                        <div className="loader">
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    ) : (
                        <p>Update</p>
                    )}
                </button>
                <button className="cancel___buttons_main" onClick={()=>navigate(-2)}>Cancel</button>
            </div>
            <ToastContainer />
        </div>
        //     )}
        // </>
    );
};
export default Editcontent;



















