import React,{useState} from "react";
import "./DeleteTwittermodel.css";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { deleteCourse } from "../../services/redux/middleware/addCourse";
import { useDispatch } from "react-redux";
import { getTwitterCourses } from "../../services/redux/middleware/getTwitterCourses";

const DeleteTwittermodel = ({ show, handleClose, taskId }) => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const handleConfirmDelete = async () => {
    console.log("Deleting task with ID:", taskId);
    setLoading(true)
   await dispatch(deleteCourse(taskId)).then(async(res) => {
      console.log("response", res)
      await dispatch(getTwitterCourses("Twitter"));
    });

    handleClose();
    setLoading(true)
  };

  return (
    <Modal
      className="modal__main__container"
      show={show}
      onHide={handleClose}
      centered
      backdropClassName="modal-backdrop"

    //   style={{
    //     width: "100%",
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //   }}
    >
      <ModalBody className="deeletemodel">
        <ModalHeader className="close__image_container-1">
          <div className="close__image_container" onClick={handleClose}>
            <img src="/Images/instagram/cross.svg" alt="Close" />
          </div>
        </ModalHeader>
        <div className="delete__task__modal_container">
          <div className="delete__task__modal__container_1">
            <div className="delete__task__modal__heading_container">
              <img
                className="logo__modal__image"
                src="/Images/instagram/deleteModel.svg"
                alt="age"
              />

              <p className="delete__task_text">Delete Course</p>

              <p className="delete__task_text_P">
                Are you sure you want to delete this course?
              </p>
            </div>
            <div className="delete__modal__button_container">
              <button
                onClick={handleClose}
                className="delete__modal_cancel_button"
              >
                Cancel
              </button>
              <button className="delete__modal_yes_button" onClick={handleConfirmDelete}>
              {isLoading ? (
                  <div className="loader">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                ) : (
                  <p>Yes I’m Sure</p>
                )}
                </button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default DeleteTwittermodel;
