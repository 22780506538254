import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getInstagramCourses } from "../middleware/getInstagramCourses";

const initialState = {
  loading: false,
  error: "",
  getInstagramCoursesData : [],
};
const getInstagramCoursesSlice = createSlice({
  name: "getInstagramCourses",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getInstagramCourses.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getInstagramCourses.fulfilled, (state, action) => {
      state.loading = false;
      state.getInstagramCoursesData = action.payload;
    });
    builder.addCase(getInstagramCourses.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error || "something wrong";
    });
  },
});
export default  getInstagramCoursesSlice.reducer;