import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./ContentLibrary.css";
import { getFolder } from "../../services/redux/middleware/getFolder";
import { useDispatch, useSelector } from "react-redux";
import Addmodel from "../../Components/Addmodel/Addmodel";
import ScreenLoader from "../../Components/ScreenLoader/ScreenLoader";

import Dropdown from "react-bootstrap/Dropdown";
import Updatemodel from "../../Components/UpdateFoldermodal/Updatemodel";
import DeletefolderModal from "../../Components/DeleteFolder/DeletefolderModal";
const ContentLibrary = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [updateshowModal, setupdateshowModal] = useState(false);
  const [selectedFolderName, setSelectedFolderName] = useState('');
  const [selectedFolderId, setSelectedFolderId] = useState('');
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);


  const handleOpenupdateModal = (folderName, folderId) => {
    setSelectedFolderName(folderName);
    setSelectedFolderId(folderId);
    setupdateshowModal(true);
  };

  const handleCloseupdateModal = () => {
    setupdateshowModal(false);
    setSelectedFolderName('');
    setSelectedFolderId('');
  };

  const handleNavigation = (id) => {
    console.log("id", id);
    navigate(`/ContentLibraryCards?folderid=${id}`);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };




  const [folderData, setFolderData] = useState([]);
  const dispatch = useDispatch();
  const FolderData = useSelector(
    (state) => state?.getFolder?.profile?.data?.contentLibrary);
  console.log("FolderData", FolderData)
   
  useEffect(() => {
   if(FolderData){
    setFolderData(FolderData)
   }
  }, [FolderData])
  
  const fetchFolder = async () => {
    setIsLoading(true);
    await dispatch(getFolder());
    
    setIsLoading(false);
  };
  useEffect(() => {
    fetchFolder();
  }, []);



  // Data array for the content items
  const contentItems = [
    { title: "Twitter Mastery", files: "12 Files" },
    { title: "Instagram Mastery", files: "12 Files" },
    { title: "Twitter Mastery", files: "12 Files" },
    { title: "Instagram Mastery", files: "12 Files" },
    { title: "Instagram Mastery", files: "12 Files" }
  ];

  return (
    <>
      {isLoading ? (
        <ScreenLoader />
      ) : (
        <div className="ContentLibraryMainDiv">
          <div className="contentdiveeeee">
            {/* <p className="instadivptag">Lectures</p> */}
            <button className="insta" onClick={handleOpenModal}>
              <img src="/Images/instagram/plus.svg" alt="" />
              Add Folder
            </button>
          </div>

          <div className="Content">
            <div className="ContentLibraryTop">
              {FolderData?.length > 0 ? (
                <>
                  {folderData?.map((item, index) => (
                    <div
                      className="ContentLibraryTop_Div1"
                      tabIndex="0"
                      
                      key={index}
                    >


                      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "24px",width:"100%" }} onClick={() => handleNavigation(item?._id)}>
                        <img src="/Images/instagram/files.svg" alt="files" />
                        <div className="ContentLibraryTop_Div2">
                          <p className="ContentLibraryTop_Div2_p1">
                            {/* {item?.folderName?.length > 10
                        ? `${item.folderName.slice(0, 10)}...`
                        : item.folderName} */}
                            {item?.folderName}
                          </p>
                          {/* <p className="ContentLibraryTop_Div2_p2">{item?.contents?.length}</p> */}
                        </div>
                      </div>
                      <Dropdown className="ddddropdd">
                        <Dropdown.Toggle className="dropdownmain11"
                          variant="success"
                          style={{
                            backgroundColor: "transparent",
                            borderColor: "transparent",
                            color: "white",
                          }}
                        >
                          <img className="deleteiconsss" src="/Images/threedot.svg" alt="" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dddropppppp" style={{ backgroundColor: "#24262B", padding: '20px', border: "none", borderRadius: "8px" }} >
                          <Dropdown.Item href="#/action-1" style={{ color: "white", marginBottom: '15px', padding: '10px 0px', display: "flex", alignItems: "center", gap: "5px" }} className="DropDownmenuOptionDivvvvv"
                            onClick={() => handleOpenupdateModal(item?.folderName, item?._id)}>
                            <img src="/Images/instagram/edit.svg" alt="" />
                            <p className="DropDownmenuOption" >Edit</p>
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-2" style={{ color: "white", padding: '10px 0px', display: "flex", alignItems: "center", gap: "5px" }} className="DropDownmenuOptionDivvvvv"
                            onClick={() => {
                              setShowDeleteModal(true);
                              setSelectedFolderId(item?._id);
                            }}
                          >
                            <img class="deleteiconsss" src="/Images/instagram/deleteimg.svg" alt="" />
                            <p className="DropDownmenuOption" >Delete</p>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  ))}
                </>
              ):(
              <div className="empyttytopmain">
                <img src="/Images/instagram/bigfolder.svg" alt="nn" />
                <p className="emptytexttt">No Folder added yet!</p>
              </div>
              )}

            </div>
          </div>

          {/* Addmodel Component */}
          <Addmodel show={showModal} handleClose={handleCloseModal} />
          <Updatemodel show={updateshowModal} handleClose={handleCloseupdateModal} folderNameProp={selectedFolderName} folderIdProp={selectedFolderId} />
          <DeletefolderModal
            show={showDeleteModal}
            handleClose={handleCloseDeleteModal}
            folderId={selectedFolderId}
          />
        </div>
      )}
    </>
  );
};

export default ContentLibrary;
