
import logo from "./logo.svg";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import SideDrawer from "./Components/Drawer/SideDrawer";
import IntagramCourse from "./Pages/instagramCourse/intagramCourse";
import AddCourse from "./Pages/instagramCourse/AddCourse/addCourse";
import InstagramCourses from "./Pages/InstagramCourses/InstagramCourses";
import TwitterCourses from "./Pages/TwitterCourses/TwitterCourses";
import DeleteModal from "./Components/DeleteModal/DeleteModal";
import ContentLibrary from './Pages/ContentLibrary/ContentLibrary';
import ContentLibraryCards from './Pages/ContentLibraryCards/ContentLibraryCards';
import Signin from "./Pages/Signin/Signin";
import InfoContentLibrary from "./Pages/InfoContentLibrary/InfoContentLibrary";
import Editcontent from "./Pages/EditContent/Editcontent";
import CourseEditing from "./Pages/instagramCourse/CourseEditting/CourseEditting"
import ProtectedRoute from "./utils/protectedRoute/ProtectedRoute";



function App() {
  const DashboardLayout = () => (
    <>
      <SideDrawer showSidebar={true} style={{ overflowX: "hidden" }}>
        <Outlet />
      </SideDrawer>
    </>
  );
  return (
    <Router>




      <Routes >
        <Route element={<DashboardLayout />}>
          <Route path="/IntagramCourse" element={
            <ProtectedRoute> <IntagramCourse /></ProtectedRoute>
          } />
          <Route path="/AddCourse" element={
            <ProtectedRoute><AddCourse /></ProtectedRoute>} />

          <Route path="/CourseEditing" element={<ProtectedRoute><CourseEditing /></ProtectedRoute>} />

          <Route path="/InstagramCourses" element={<ProtectedRoute><InstagramCourses /></ProtectedRoute>} />
          <Route path="/TwitterCourses" element={<ProtectedRoute><TwitterCourses /></ProtectedRoute>} />
          <Route path='/ContentLibrary' element={<ProtectedRoute><ContentLibrary /></ProtectedRoute>} />
          <Route path='/ContentLibraryCards' element={<ProtectedRoute><ContentLibraryCards /></ProtectedRoute>} />
          <Route path='/InfoContentLibrary' element={<ProtectedRoute><InfoContentLibrary /></ProtectedRoute>} />
          <Route path="/Editcontent/:folderid/:id" element={<ProtectedRoute><Editcontent /></ProtectedRoute>} />
        </Route>
        <Route path='/' element={<Signin />} />


      </Routes>
    </Router>
  );
}

export default App;
