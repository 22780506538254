import React, { useState, useEffect } from "react";
import { DropzoneArea } from "material-ui-dropzone";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import "./InfoContentLibrary.css";
import { addContent } from "../../services/redux/middleware/addContent";
import { ToastContainer, toast } from "react-toastify";
import ScreenLoader from "../../Components/ScreenLoader/ScreenLoader";
import Videoloader from "../../Components/videoloader/ScreenLoader";
const InfoContentLibrary = () => {
  const dispatch = useDispatch();
  const [thumbnailImage, setThumbnailImage] = useState(null); // For selected thumbnail
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [caption1, setCaption1] = useState("");
  const [caption2, setCaption2] = useState("");
  const [caption3, setCaption3] = useState("");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const folderid = queryParams.get("folderid");
  const navigate = useNavigate();
  const [imagePreviewUrl, setImagePreviewUrl] = useState(); // State to store image preview URL
  const [videoPreviewUrl, setVideoPreviewUrl] = useState(null);
  const [videoloading, setvideoloading] = useState(false);
  // Handle file change
  // const handleImageChange = (files) => {
  //   const selectedFile = files[0];
  //   console.log("selected media",selectedFile)

  //   if (selectedFile) {
  //     setvideoloading(true);
  //     setThumbnailImage(selectedFile); // Set the selected image or video

  //     setTimeout(() => {
  //       setvideoloading(false);
  //     }, 2000);

  //     if (selectedFile.type.startsWith("image/")) {
  //       setImagePreviewUrl(URL.createObjectURL(selectedFile));
  //       setVideoPreviewUrl(null);
  //     } else if (selectedFile.type.startsWith("video/")) {
  //       setVideoPreviewUrl(URL.createObjectURL(selectedFile));
  //       setImagePreviewUrl(null);
  //     }
  //   }
  // };

  const handleImageChange = (event) => {
    const selectedFile = event.target.files[0];
    console.log("selected media", selectedFile);

    if (selectedFile) {
      setvideoloading(true);
      setThumbnailImage(selectedFile); // Set the selected image or video

      setTimeout(() => {
        setvideoloading(false);
      }, 2000);

      if (selectedFile.type.startsWith("image/")) {
        setImagePreviewUrl(URL.createObjectURL(selectedFile));
        setVideoPreviewUrl(null);
      } else if (selectedFile.type.startsWith("video/")) {
        setVideoPreviewUrl(URL.createObjectURL(selectedFile));
        setImagePreviewUrl(null);
      }
    }
  };

  useEffect(() => {
    if (thumbnailImage) {
      console.log("Thumbnail Image updated:", thumbnailImage);
    }
  }, [thumbnailImage]);

  const handlePublish = () => {
    console.log("Thumbnail in Publish:", thumbnailImage);

    if (
      !title ||
      !description ||
      !caption1 ||
      !caption2 ||
      !caption3 ||
      !thumbnailImage
    ) {
      toast.warn("Please fill all fields", {
        position: "top-right",
        autoClose: 3000,
      });
    } else {
      setLoading(true);

      const data = {
        title,
        description,
        caption1,
        caption2,
        caption3,
        thumbnailImage,
      };

      const dataWithId = {
        data,
        folderid,
      };

      dispatch(addContent(dataWithId)).then((res) => {
        console.log("Response:", res);
        
        const message = res?.payload?.message;

        if (message && message.startsWith("Content added successfully")) {
          toast.success("Content added successfully!", {
            position: "top-right",
            autoClose: 1500,
          });
          
        } else {
          toast.error("Unable to add content right now!", {
            position: "top-right",
            autoClose: 1500,
          });
         
        }
        // setLoading(false); 
        setTimeout(() => {
          navigate(-1);
          setLoading(false); 
        }, 3000);
      });
    }
  };

  return (
    // <>
    //   {isLoading ? (
    //     <ScreenLoader />
    //   ) : (
    <div className="info__main_containerr">
      <p className="InfoContentLibraryMain1_p">Content Library Info</p>
      <div className="InfoContentLibraryMain1_div">
        <div className="add__course__input_container">
          <p className="infocontact__inputlabel">Title</p>
          <input
            className="infocontent__input_placeholder"
            type="text"
            placeholder="Enter Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>

        <div className="add__course__input_container">
          <p className="infocontact__inputlabel">Description</p>
          <textarea
            className="info_content__input_placeholder_description"
            placeholder="Enter Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>

        <div className="add__course__input_container">
          <p className="infocontact__inputlabel">Caption 1</p>
          <textarea
            className="info_content__input_placeholder_description"
            placeholder="Enter"
            value={caption1}
            onChange={(e) => setCaption1(e.target.value)}
          />
        </div>

        <div className="add__course__input_container">
          <p className="infocontact__inputlabel">Caption 2</p>
          <textarea
            className="info_content__input_placeholder_description"
            placeholder="Enter"
            value={caption2}
            onChange={(e) => setCaption2(e.target.value)}
          />
        </div>

        <div className="add__course__input_container">
          <p className="add__course__inputlabel">Caption 3</p>
          <textarea
            className="add__course__input_placeholder_description"
            placeholder="Enter"
            value={caption3}
            onChange={(e) => setCaption3(e.target.value)}
          />
        </div>

        <div className="add__course__input_container">
          <p className="infocontact__inputlabel">Thumbnail Media</p>
          <div
            className="image__dropzone__main"
            style={{
              width: imagePreviewUrl || videoPreviewUrl ? "300px" : "100%",
            }}
          >
            {/* <DropzoneArea
                  filesLimit={1}
                  acceptedFiles={["image/*", "video/*"]}
                  maxFileSize={500000000}
                  dropzoneText="Upload Media"
                  onChange={handleImageChange}
                />
                <input type="file"/> */}

            <label style={{ cursor: "pointer" }}>
              <input
                type="file"
                accept="image/*,video/*"
                style={{ display: "none" }}
                onChange={handleImageChange}
              />
              <div className="maindropzzz">
                <img src="/Images/instagram/dropimage.svg" alt="rr" />
                <span className="add__course__inputlabel"> Upload media</span>
              </div>
            </label>
            {videoloading ? (
              <Videoloader />
            ) : (
              <>
                {imagePreviewUrl && (
                  <div className="imageselected">
                    <img
                      src={imagePreviewUrl}
                      alt="Selected"
                      style={{
                        width: "300px",
                        borderRadius: "16px",
                        height: "300px",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                )}
                {videoPreviewUrl && (
                  <div
                    className="video-preview-container"
                    style={{ marginTop: "16px", width: "100%" }}
                  >
                    <video
                      src={videoPreviewUrl}
                      controls
                      style={{ width: "100%", borderRadius: "16px" }}
                      className="videopreview_llll"
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <div className="publish__button__main__containerr">
        <button className="publish___buttons_main" onClick={handlePublish} disabled={Loading}>
          {Loading ? (
            <div className="loader">
              <div></div>
              <div></div>
              <div></div>
            </div>
          ) : (
            <p>Publish</p>
          )}
        </button>
        <button className="cancel___buttons_main" onClick={()=>navigate(-2)}>Cancel</button>
      </div>
      <ToastContainer />
    </div>
    //   )}
    // </>
  );
};

export default InfoContentLibrary;
